import {
  PrismicImage,
  PrismicRichText,
  PrismicPageDefinition,
  PrismicFileLink,
} from "./types";

export type TypeFactRows = {
  parent_tab: number;
  title1: PrismicRichText;
  value_1: boolean;
  value_2: boolean;
};

export type TypeHomepageTabs = {
  tab_header: string;
  tab_id: string;
  tab_heading_intro: PrismicRichText;
  tab_cta_image: PrismicImage;
  tab_quote_image: PrismicImage;
  tab_cta_text: PrismicRichText;
  tab_table_img: PrismicImage;
  tab_bottom_img: PrismicImage;
  tab_bottom_text: PrismicRichText;
  tab_quote_subcopy: PrismicRichText;
  html: string;
};

export type TypeIOVSSiteFactoryPageData = {
  title: string;
  meta_title: string;
  page_meta_thumbnail: PrismicImage;
  page_meta_description: string;

  tabs_group: Array<TypeHomepageTabs>;
  fact_table_row: Array<TypeFactRows>;

  hero_text: PrismicRichText;
  hero_subtitle: PrismicRichText;
  cta_button_text: string;
  cta_button_text_2: string;
};

export type TypeIOVSSiteFactoryPageQueryResult = {
  allPrismicAmazeeioVsSiteFactory: {
    edges: Array<{
      node: { data: TypeIOVSSiteFactoryPageData };
    }>;
  };
};

export const getPage = (
  data: TypeIOVSSiteFactoryPageQueryResult
): TypeIOVSSiteFactoryPageData => {
  return data.allPrismicAmazeeioVsSiteFactory.edges[0].node.data;
};
